import service from "@/api/service";
import { GetResponse } from "@/api/role-setting-shop/response";

/**
 * 権限ロール店舗名取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-role-setting-shop");
  return response.data as GetResponse;
}
