import service from "@/api/service";
import {
  SearchRequest,
  DetailRequest,
  DeleteRequest,
  RegisterRequest
} from "@/api/role-setting/request";
import { SearchResponse, DetailResponse } from "@/api/role-setting/response";
import { CommonResponse } from "@/api/response";

/**
 * 権限ロール一覧検索APIをコールします。
 *
 * @param searchRequest 検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-role-setting", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 権限ロール詳細情報取得APIをコールします。
 *
 * @param detailRequest 詳細情報取得のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/get-role-setting", detailRequest);
  return response.data as DetailResponse;
}

/**
 * 権限ロール情報登録更新APIをコールします。
 *
 * @param registerRequest 登録更新のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-role-setting", registerRequest);
  return response.data as CommonResponse;
}

/**
 * 権限ロール削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-role-setting", deleteRequest);
  return response.data as CommonResponse;
}
